import type { ConfigData, User } from 'rachael-server';
import { createContext, useContext } from 'react';

/**
 * ConfigData is the data type returned by the /data/config API endpoint. It has an optional "user"
 * attribute. Config is the same thing, but with the user no longer optional. We make this
 * distinction because the vast majority of the application code assuemes the existence of a logged
 * in user, but we still need to be able to handle the case where a user is *not* logged in. The
 * former can use the useConfig hook (exported as default), while the latter can use the special
 * useConfigData hook.
 */
export type Config = ConfigData & { user: User };

export const ConfigContext = createContext<ConfigData | null>(null);

export function useConfigData(): ConfigData {
  const configData = useContext(ConfigContext);

  if (!configData) {
    throw new Error('useConfigData called outside a ConfigData provider');
  }

  return configData;
}

export default function useConfig(): Config {
  const configData = useConfigData();

  if (!configData.user) {
    throw new Error('No logged in user');
  }

  return configData as Config;
}

export class SuperUserConfig {
  static setIsViewingAsCustomer(isImpersonating: boolean) {
    // If you update this dont forget to also update index.html
    localStorage.setItem('superuser.viewAsCustomer', `${isImpersonating}`);

    // This isnt exactly reactive but in this case a full refresh makes sense and because
    // we pass this through to the server in index.html via the config endpoint
    window?.location?.reload();
  }
}
