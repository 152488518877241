"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTheme = createReplicantTheme;
const material_1 = require("@mui/material");
const lodash_1 = require("lodash");
const theme_1 = require("./theme");
function createReplicantTheme(options, ...args) {
    const combinedTheme = (0, lodash_1.merge)(theme_1.theme, options || {});
    return (0, material_1.createTheme)(combinedTheme, ...args);
}
