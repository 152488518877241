import { Box, useTheme } from '@mui/material';

export default () => {
  const { palette } = useTheme();

  return (
    <Box
      role='progressbar'
      component={'svg'}
      xmlns='http://www.w3.org/2000/svg'
      width={200}
      height={200}
      viewBox='0 0 100 100'
      sx={{ display: 'block', margin: '0 auto' }}>
      <circle cx='50' cy='50' r='20.3812' fill='none' stroke={palette.primary.main} strokeWidth='2'>
        <animate
          attributeName='r'
          calcMode='spline'
          values='0;30'
          keyTimes='0;1'
          dur='1.5'
          keySplines='0 0.2 0.8 1'
          begin='-0.75s'
          repeatCount='indefinite'
        />
        <animate
          attributeName='opacity'
          calcMode='spline'
          values='1;0'
          keyTimes='0;1'
          dur='1.5'
          keySplines='0.2 0 0.8 1'
          begin='-0.75s'
          repeatCount='indefinite'
        />
      </circle>
      <circle cx='50' cy='50' r='2.9505' fill='none' stroke={palette.primary.light} strokeWidth='2'>
        <animate
          attributeName='r'
          calcMode='spline'
          values='0;30'
          keyTimes='0;1'
          dur='1.5'
          keySplines='0 0.2 0.8 1'
          begin='0s'
          repeatCount='indefinite'
        />
        <animate
          attributeName='opacity'
          calcMode='spline'
          values='1;0'
          keyTimes='0;1'
          dur='1.5'
          keySplines='0.2 0 0.8 1'
          begin='0s'
          repeatCount='indefinite'
        />
      </circle>
    </Box>
  );
};
