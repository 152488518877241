"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CSSVarsThemeProvider = CSSVarsThemeProvider;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const theme_1 = require("./theme");
function CSSVarsThemeProvider(props) {
    const { children } = props;
    const extendedTheme = (0, material_1.experimental_extendTheme)(theme_1.theme);
    return (0, jsx_runtime_1.jsx)(material_1.Experimental_CssVarsProvider, { theme: extendedTheme, children: children });
}
