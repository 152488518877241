"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupCard = GroupCard;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const icon_button_1 = require("./icon-button");
const Card = (0, material_1.styled)(material_1.Paper)(({ theme }) => ({
    padding: `${theme.spacing(2)} ${theme.spacing(0)}`,
    background: (0, material_1.alpha)(theme.palette.primary.main, theme.palette.action.hoverOpacity),
    borderWidth: '0.5px', // consider 1px for firefox
    borderColor: theme.palette.grey[400],
}));
function GroupCard(props) {
    const { children, onDelete, draggable, dense = false, removable, dragHandleProps } = props;
    const theme = (0, material_1.useTheme)();
    return ((0, jsx_runtime_1.jsx)(Card, { variant: 'outlined', children: (0, jsx_runtime_1.jsxs)(material_1.Grid, { container: true, direction: 'row', children: [(0, jsx_runtime_1.jsx)(material_1.Grid, { item: true, hidden: !draggable, alignSelf: 'center', paddingLeft: theme.spacing(1), children: (0, jsx_runtime_1.jsx)("div", { ...dragHandleProps, children: (0, jsx_runtime_1.jsx)(icon_button_1.DragButton, {}) }) }), (0, jsx_runtime_1.jsx)(material_1.Grid, { container: true, item: true, xs: true, direction: 'column', rowSpacing: theme.spacing(dense ? 1 : 2), children: children }), (0, jsx_runtime_1.jsx)(material_1.Grid, { item: true, hidden: !removable, paddingLeft: theme.spacing(1), children: (0, jsx_runtime_1.jsx)(icon_button_1.DeleteButton, { onClick: onDelete }) })] }) }));
}
