"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeProvider = ThemeProvider;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const create_theme_1 = require("./create-theme");
function ThemeProvider(props) {
    const { theme: themeOverride, ...otherProps } = props;
    const theme = typeof themeOverride === 'function' ? themeOverride((0, create_theme_1.createTheme)()) : (0, create_theme_1.createTheme)(themeOverride);
    return (0, jsx_runtime_1.jsx)(material_1.ThemeProvider, { theme: theme, ...otherProps });
}
