"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextField = TextField;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const minimal_1 = require("./minimal");
function TextField(props) {
    if (props.variant === minimal_1.MinimalVariantName) {
        return (0, jsx_runtime_1.jsx)(minimal_1.MinimalTextField, { ...props });
    }
    return (0, jsx_runtime_1.jsx)(material_1.TextField, { ...props });
}
