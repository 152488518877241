import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings';
import { runStatsigAutoCapture } from '@statsig/web-analytics';
import { type PropsWithChildren, useEffect } from 'react';

const StatsigEnviroments = {
  dev: 'development',
  stage: 'staging',
  prod: 'production',
} as const;

type StatsigWrapperProps = PropsWithChildren<{
  userEmail?: string;
  sdkKeyOverride?: string;
}>;

const isTest = typeof process !== 'undefined' && process.env.NODE_ENV === 'test';

/**
 * Set up (or not) and return Statsig client
 */
export const StatsigWrapper = (props: StatsigWrapperProps): JSX.Element => {
  const isAnalyticsEnabled = process.env['PUBLIC_WEB_ANALYTICS_ENABLED'] === 'true';
  const statsigKey =
    props.sdkKeyOverride ?? (process.env['PUBLIC_STATSIG_KEY'] || 'no-key-detected');
  const env = process.env['PUBLIC_ENV'];

  const { client } = useClientAsyncInit(
    statsigKey,
    { userID: props.userEmail },
    {
      networkConfig: { preventAllNetworkTraffic: isTest },
      disableLogging: !isAnalyticsEnabled,
      environment: {
        tier: StatsigEnviroments[(env || 'dev') as keyof typeof StatsigEnviroments],
      },
    }
  );

  useEffect(() => {
    if (client && isAnalyticsEnabled) {
      runStatsigAutoCapture(client);
    }
  }, [client, isAnalyticsEnabled]);

  if (!isAnalyticsEnabled && !isTest) {
    console.log(
      `Statsig web analytics disabled (flag = ${isAnalyticsEnabled}, key = ${
        statsigKey ? 'provided' : 'not provided'
      })`
    );
  }

  return <StatsigProvider client={client}>{props.children}</StatsigProvider>;
};
