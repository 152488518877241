"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = DashedLineAccordion;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
const StyledAccordion = (0, material_1.styled)(material_1.Accordion)(({ theme }) => ({
    boxShadow: 'none',
    backgroundColor: 'transparent',
    ' .MuiInputBase-root': {
        backgroundColor: theme.palette.background.default, // TODO: move to theme for all input bases after checking downstream impact?
    },
}));
function DashedLineAccordion(props) {
    return (0, jsx_runtime_1.jsx)(StyledAccordion, { ...props, disableGutters: true, TransitionComponent: material_1.Collapse });
}
