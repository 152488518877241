"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormControlLabel = FormControlLabel;
const jsx_runtime_1 = require("react/jsx-runtime");
const material_1 = require("@mui/material");
function FormControlLabel(props) {
    const { typography, ...labelProps } = props;
    const componentsProps = typography ? { typography: { variant: typography } } : {};
    return (0, jsx_runtime_1.jsx)(material_1.FormControlLabel, { ...labelProps, componentsProps: componentsProps });
}
